import styled from "styled-components";

export const Wrapper = styled.div`
	padding-top: 5rem;
	background: linear-gradient(
			to right,
			rgba(255, 0, 0, 0.6),
			rgba(203, 0, 55, 0.8)
		),
		url(${(props) => props.imgUrl});
	background-size: cover;
	/* background-attachment: fixed; */
	min-height: 310px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;

	&::after {
		// content: "";
		// background: white;
		// width: 100vw;
		// height: 137px;
		// margin-top: -5rem;
	}
	@media screen and (min-width: 720px) {
		&::after {
			// margin-top: -8rem;
			// height: 130px;
		}
	}
	@media screen and (min-width: 1150px) {
		&::after {
			// height: 211px;
			// margin-top: -13rem;
			// clip-path: polygon(230% 100%, 85% 0%, -187% 100%);
		}
	}
`;

export const Content = styled.div`
	width: 100vw;
	max-width: 1600px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	color: white;
	& h1 {
		color: white;
		margin: 0;
		padding-bottom: 1rem;
	}
	& p {
		font-size: 1.2rem;
		font-weight: 400;
	}
	& > img {
		display: none;
	}
	@media screen and (min-width: 1150px) {
		min-height: 320px;
		& h1 span {
			padding-left: 3rem;
		}
		& > img {
			filter: drop-shadow(var(--box-shadow));
			display: unset;
			margin: 0;
			height: 625px;
			animation: mover 2s infinite alternate;
			z-index: 100;
			margin-top: 5rem;
			margin-right: 30px;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
	z-index: 100;
	padding: 2rem;
	width: 100%;
	box-sizing: border-box;
	@media screen and (min-width: 1036px) {
		max-width: calc(100% - 350px);
	}
	@media screen and (max-width: 720px) {
		padding-bottom: 0;
	}
`;

export const Links = styled.div`
	margin: 2rem 0;
	display: flex;
	flex-wrap: wrap;
	& a {
		text-decoration: none;
		color: white;
		font-weight: 600;
		border: 1px solid white;
		background: none;
		margin: 0 1.5rem 0.5rem 0;
		padding: 0.75rem 2rem;
		border-radius: 10px;
		transition: 0.3s ease;
		&:hover {
			background-color: white;
			color: var(--red);
			cursor: pointer;
		}
		& span {
			margin-left: 2rem;
		}
		@media screen and (max-width: 680px) {
			width: 100%;
   			max-width: 220px;
			margin: 0.25rem auto;
		}
	}
`;

export const TextContainer = styled.div`
	max-width: 700px;
	& p {
		font-weight: 600;
	}
`;

export const Partner = styled.div`
	margin-top: 3rem;
	color: var(--text);
	font-weight: 600;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	/* width: 90%; */
	z-index: 100;
	& span {
		min-width: 150px;
	}
	& img {
		filter: grayscale();
		height: 30px;
		margin: 0.5rem 1.25em 0 0;
		max-width: 70%;
	}
	& img:not(:last-child) {
		border-right: 1px solid #dfdfdf;
		padding-right: 1.25rem;
	}
	@media screen and (max-width: 600px) {
		flex-direction: column;
		& span {
			min-width: 0;
		}
	}
	@media screen and (min-width: 1150px) {
		margin-top: 10rem;
	}
`;

export const ImgWrapper = styled.div`
	overflow: hidden;
	display: flex;
	@media screen and (max-width: 600px) {
		overflow: scroll;
	}
`;
